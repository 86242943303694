import { Brand } from './typings'

const oldnavy: Brand = {
  clientInfo: {
    displayName: 'Old Navy',
    accountNumberLengths: [16],
    footerDisplay: 'Oldnavy.com',
    footerLink: 'https://www.Oldnavy.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'RiqHs9cOqfXkEHAdTIfgCzlKol3zVVAN',
      production: 'gwqynHiRIQfCU7eRM3QR99FLLNUV3ItG'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Old Navy credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Old Navy en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'Oldnavy.com',
    hostname: 'oldnavy.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: 'https://oldnavy.barclaysus.com/',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Navyist Rewards Visa® Credit Card, Navyist Rewards Credit Card, or Gap Inc. Visa Signature® Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Navyist Rewards Visa® Credit Card, Navyist Rewards Credit Card, or Gap Inc. Visa Signature® Card. The use of this site is governed by the use of the Synchrony Bank Online Privacy Policy, which is different from the privacy policy of Old Navy.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-877-222-6868',
      dualcard: '1-866-450-5294',
      fraud: '1-877-350-4039',
      customerService: '1-855-622-2693'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/generic/@oldnavy/en/html/ess003072.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@oldnavy/en/html/ess013975.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@oldnavy/en/html/ess013977.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@oldnavy/en/html/ess013979.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#0b2f52'
      },
      secondary: {
        hover: '#cfdae5'
      },
      background: '#003764'
    },
    field: {
      icon: '#003764',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#00487b',
      label: '#00487b'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#0e3e6b',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#FFFFFF',
    bodyBackground: '#FFFFFF',
    iconBackground: '#00487b',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '',
    headerDivider: '#7FBDE8',
    footerDivider: '#ffffff',
    contrastingText: '#ffffff',
    bodyText: '#333333',
    languageSelector: '#ed0a18',
    focus: '',
    link: '#003764',
    footer: {
      background: '#F2F2F2',
      text: '#333333',
      link: '#333333'
    }
  },
  images: {
    logo: {
      en: '/assets/oldnavy/logo.jpg',
      es: '/assets/oldnavy/logo.jpg'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/oldnavy/promo.png',
      es: '/assets/oldnavy/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default oldnavy
